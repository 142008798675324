import {React, useEffect} from "react";
import Swal from "sweetalert2";
import "../index.css";
import logo from "../images/LogoTransparente.png";

const Header = () => {
  const styleContainerPrincipal = {
    backgroundColor: "white",
    padding: "15px",
    borderRadius: "10px",
  };

  const styleUserName = {
    border: "1px solid black",
    borderRadius: "8px",
    padding: "10px",
    color: "black",
  };

  const nameUser =
    localStorage.getItem("name") + " " + localStorage.getItem("lastName");
  const userType = localStorage.getItem("userType") || ""; // Obtener el userType del localStorage
  let timeout;

  const onLogout = () => {
    Swal.fire({
      title: "Cerrar sesión",
      text: "¿Estás seguro de que deseas cerrar sesión?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Salir",
      cancelButtonText: "Regresar",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.reload();
      } else {
        Swal.close();
      }
    });
  };


  const handleScan = (e) => {
    clearTimeout(timeout); 
    document.getElementById("messageScann").style.display = "unset";
    const value = e;
  
    timeout = setTimeout(() => {
      console.log(value.split("=")[1]);  
  
      // Crear un elemento de enlace dinámicamente
      const link = document.createElement("a");
      link.href = "/=" + value.split("=")[1];  
      link.target = "_blank"; 
      link.click();  
      document.getElementById("scannCode").value = ""; 
      document.getElementById("messageScann").style.display = "none";
    }, 500); 
    
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-custom fixed-top" style={{boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)'}}>
      <div className="container-fluid" style={styleContainerPrincipal}>
        <div className="navbar-brand" style={styleUserName}>
          <img
            src={logo}
            alt=""
            style={{ width: "60px", marginRight: "10px", cursor: 'pointer' }}
            onClick={()=> window.location.href= '/'}
          />
          <span>{nameUser}</span>
        </div>
        <div className="collapse navbar-collapse">
          <ul className="navbar-nav">
            {userType === "0" ? (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard">
                    Usuarios
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/sucursales">
                    Sucursales
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/clientes">
                    Pacientes
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/citas">
                    Citas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/pedidos">
                    Pedidos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/facturacion/CrearFactura">
                    Facturación
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/facturas/gestion">
                    Gestión Facturas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/cortes/corteIndividual">
                    Corte de Caja
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/cortes/panelAdmin">
                    Panel Cortes de Caja
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/whatsapp/enviarPromocion">
                    Enviar Promociones Whatsapp
                  </a>
                </li>
              </>
            ) : userType === "1" ? (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard">
                  Pacientes
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/citas">
                    Citas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/pedidos">
                    Pedidos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/facturacion/CrearFactura">
                    Facturación
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/facturas/gestion">
                    Gestión Facturas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/cortes/corteIndividual">
                    Corte de Caja
                  </a>
                </li>
              </>
            ) : userType === "2" ? (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard">
                  Pacientes
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/citas">
                    Citas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/pedidos">
                    Pedidos
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/facturacion/CrearFactura">
                    Facturación
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/facturas/gestion">
                    Gestión Facturas
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/cortes/corteIndividual">
                    Corte de Caja
                  </a>
                </li>
              </>
            ) : userType === "4" ? (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard">
                  Pacientes
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/dashboard/citas">
                    Citas
                  </a>
                </li>
              </>
            ) : null
            }
          </ul>
        </div>
        <div style={{position: 'relative'}}>
          <span style={{position: 'absolute', top: '10px', left: '10px', fontSize: '12px', color: '#6da296', fontWeight: '800', display: 'none'}} id="messageScann">Escaneando...</span>
          <input type="text"  placeholder="Click y escanee QR" className="form-control inputTextTransparent" id="scannCode" onInput={(e)=>{ handleScan(e.target.value)}} style={{width: '200px', marginRight: '20px', cursor: 'pointer'}} />
        </div>
        <button onClick={onLogout} className="btn btn-secondary">
          Cerrar sesión
        </button>
      </div>
    </nav>
  );
};

export default Header;
