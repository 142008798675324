import React, { useState, useEffect, useRef } from "react";
import axios from "../../api/axiosConfig";
import Swal from "sweetalert2";
import Header from "../Header";
import Pagination from "./../Pagination";
import "../../index.css";
import Loader from "../loader";
import { io } from "socket.io-client";

const Sucursales = () => {
  const [direccion, setDireccion] = useState("");
  const [rfc, setRfc] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [idEncargadoFormulario, setIdEncargadoFormulario] = useState(0);
  const [idSucursalEdited, setIdSucursalEdited] = useState(0);
  const [sucursalesList, setSucursalesList] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [totalSucursales, setTotalSucursales] = useState(0);
  const [currentSucursales, setCurrentSucursales] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [numeroSucursal, setNumeroSucursal] = useState("");
  const [encargado, setEncargado] = useState("");
  const [encargadoEdicion, setEncargadoEdit] = useState("");
  const [encargados, setEncargados] = useState([]);
  const [CSD, setCSD] = useState("");
  const [passCSD, setPassCSD] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const csdRef = useRef(null);
  const privateKeyRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const formStyles = {
    textAlign: "center",
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '10px',
    marginBottom: '20px'
  };

  // Estado para el paginado
  const [currentPage, setCurrentPage] = useState(1);
  const [SucursalesPerPage] = useState(10);

  useEffect(() => {
    fetchEncargados();
    fetchSucursales();
  }, [SucursalesPerPage]);

  //Funcion para filtrar usuarios por nombre de menera flexible
  const filterSucursales = (page, searchTermR) => {
    setSearchTerm(searchTermR);
    let registrosIni = (page - 1) * SucursalesPerPage;

    let filtered = sucursalesList.filter((sucursal) =>
      sucursal.direccion.toLowerCase().includes(searchTermR.toLowerCase())
    );

    const totalPages = Math.ceil(filtered.length / SucursalesPerPage);
    setTotalSucursales(totalPages);

    return filtered.slice(registrosIni, registrosIni + SucursalesPerPage);
  };

  const fetchSucursales = async () => {
    try {
      const response = await axios.get("/sucursales");
      const fetchedSucursales = response.data.data;
      setSucursalesList(fetchedSucursales);

      console.log(response.data.data);
      const totalPages = Math.ceil(
        fetchedSucursales.length / SucursalesPerPage
      );
      setTotalSucursales(totalPages);

      setCurrentSucursales(fetchedSucursales.slice(0, SucursalesPerPage));
    } catch (error) {
      console.error("Error al obtener sucursales:", error);
    }
  };

    //Socket that detects new users created
    useEffect(() => {
      const socket = io(process.env.REACT_APP_API_BASE_URL,{
        extraHeaders: {
          'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
        },
        transports: ["websocket", "polling"]
      });
  
      // Escuchar eventos de nuevos clientes
      socket.on("SucursalAdded", () => {
        fetchSucursales();
      });
  
      return () => socket.disconnect(); // Limpiar al desmontar
    }, []);

    //Socket that detects pedidos updated
    useEffect(() => {
      const socket = io(process.env.REACT_APP_API_BASE_URL,{
        extraHeaders: {
          'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
        },
        transports: ["websocket", "polling"]
      });
  
      // Escuchar eventos de nuevos clientes
      socket.on("SucursalUpdated", () => {
        fetchSucursales();
      });
  
      return () => socket.disconnect(); // Limpiar al desmontar
    }, []);


    //Socket that detects pedidos deleted
    useEffect(() => {
      const socket = io(process.env.REACT_APP_API_BASE_URL,{
        extraHeaders: {
          'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
        },
        transports: ["websocket", "polling"]
      });
  
      // Escuchar eventos de nuevos clientes
      socket.on("SucursalDeleted", () => {
        fetchSucursales();
      });
  
      return () => socket.disconnect(); // Limpiar al desmontar
    }, []);

  const fetchEncargados = () => {
    axios
      .get("/encargados")
      .then((response) => {
        if (response.data.success) {
          setEncargados(response.data.data);
        } else {
          Swal.fire({
            title: "¡Error!",
            text: "No se pudieron cargar los encargados.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        console.error("Error al obtener encargados:", error);
        Swal.fire({
          title: "¡Error!",
          text: "Ocurrió un error al obtener los encargados.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleToggleActive = (sucursalId, userPassword) => {
    // Realizar una solicitud PUT a tu endpoint de activación de sucursal
    axios
      .put(`/sucursales/${sucursalId}/activar`)
      .then((response) => {
        if (response.data.success) {
          // Si la solicitud fue exitosa, actualiza la lista de sucursales
          fetchSucursales();
          // Muestra un mensaje de éxito al sucursal
          Swal.fire({
            title: "¡Sucursal activada!",
            text: "La sucursal ha sido activado exitosamente.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          // Si la solicitud no fue exitosa, muestra un mensaje de error al sucursal
          Swal.fire({
            title: "¡Error!",
            text: "No se pudo activar la sucursal.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        // Si hubo un error en la solicitud, muestra un mensaje de error al sucursal
        console.error("Error al activar la sucursal:", error);
        Swal.fire({
          title: "¡Error!",
          text: "Ocurrió un error al intentar activar la sucursal.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleToggleDeactive = (sucursalId) => {
    // Realizar una solicitud PUT a tu endpoint de desactivación de sucursal
    axios
      .put(`/sucursales/${sucursalId}/desactivar`)
      .then((response) => {
        if (response.data.success) {
          // Si la solicitud fue exitosa, actualiza la lista de sucursales
          fetchSucursales();
          // Muestra un mensaje de éxito al sucursal
          Swal.fire({
            title: "Sucursal desactivada!",
            text: "La sucursal ha sido desactivado exitosamente.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          // Si la solicitud no fue exitosa, muestra un mensaje de error al sucursal
          Swal.fire({
            title: "¡Error!",
            text: "No se pudo desactivar la sucursal.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        // Si hubo un error en la solicitud, muestra un mensaje de error al sucursal
        console.error("Error al desactivar la sucursal:", error);
        Swal.fire({
          title: "¡Error!",
          text: "Ocurrió un error al intentar desactivar la sucursal.",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const handleCreateOrUpdateBranch = () => {
    setLoading(true);

    if (editMode !== null) {
      const encargado = encargados.find(
        (encargado) => encargado.id == idEncargadoFormulario
      );
      const nombreEncargado = encargado
        ? `${encargado.name} ${encargado.lastName} ${encargado.secondLastName}`
        : "";

      const sucursal = sucursalesList.find((sucursal) => sucursal.id == idSucursalEdited);
      axios
        .put(`/sucursales/${idSucursalEdited}`, {
          sucursalNumber: numeroSucursal !== "" ? numeroSucursal : sucursal.sucursalNumber,
          direccion: direccion !== "" ? direccion : sucursal.direccion,
          idEncargado: idEncargadoFormulario !== 0 ? idEncargadoFormulario : sucursal.idEncargado,
          encargado: nombreEncargado !== "" ? nombreEncargado : sucursal.encargado,
          rfc: rfc !== "" ? rfc : sucursal.rfc,
          razonSocial: razonSocial !== "" ? razonSocial : sucursal.razonSocial,
          codigoPostal: codigoPostal !== "" ? codigoPostal : sucursal.codigoPostal,
          CSD: CSD !== "" ? CSD : sucursal.CSD,
          privateKey: privateKey !== "" ? privateKey : sucursal.privateKey,
          passCSD: passCSD !== "" ? passCSD : sucursal.passCSD,
        })
        .then((response) => {
          console.log(response)
          setLoading(false);
          setNumeroSucursal("");
          setDireccion("");
          setEncargado("");
          setCodigoPostal("");
          setRazonSocial("");
          setCSD("");
          setPassCSD("");
          setPrivateKey("");
          setRfc("");
          setIdEncargadoFormulario(0);

          // Mostrar un mensaje de éxito al sucursal
          Swal.fire({
            title: "¡Sucursal actualizada!",
            text: "Los cambios se han guardado exitosamente.",
            icon: "success",
            confirmButtonText: "OK",
          }).then((response) =>{
            if (response.isConfirmed) {
              window.location.reload();
            }
          });
        }).catch((error) => {
          console.error("Error al actualizar sucursal:", error);
          setLoading(false);
          Swal.fire({
            title: "¡Error!",
            text: "Ocurrió un error al intentar actualizar la sucursal.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });

      setIdSucursalEdited(null);
    } else {
      const encargado = encargados.find(
        (encargado) => encargado.id == idEncargadoFormulario
      );
      const nombreEncargado = encargado
        ? `${encargado.name} ${encargado.lastName} ${encargado.secondLastName}`
        : "";

      if (
        numeroSucursal === "" ||
        direccion === "" ||
        rfc === "" ||
        razonSocial === "" ||
        !idEncargadoFormulario ||
        codigoPostal === ""
      ) {
        setLoading(false);
        Swal.fire({
          title: "¡Error al insertar!",
          text: "Hay datos vacíos, favor de rellenarlos.",
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }

      axios
        .post("/crearSucursal", {
          numeroSucursal,
          direccion,
          idEncargado: idEncargadoFormulario,
          encargado: nombreEncargado,
          rfc: rfc,
          razonSocial: razonSocial,
          codigoPostal,
          CSD,
          privateKey,
          passCSD,
        })
        .then((response) => {
          if (response.data.success) {
            setLoading(false);
            Swal.fire({
              title: "¡Sucursal creada!",
              text: "La nueva sucursal ha sido creada exitosamente.",
              icon: "success",
              confirmButtonText: "OK",
            });
            fetchSucursales();

            setNumeroSucursal("");
            setDireccion("");
            setEncargado("");
            setCodigoPostal("");
            setRazonSocial("");
            setCSD("");
            setPassCSD("");
            setPrivateKey("");
            setRfc("");
            setIdEncargadoFormulario(0);
            if (csdRef.current) csdRef.current.value = null;
            if (privateKeyRef.current) privateKeyRef.current.value = null;
          } else {
            setLoading(false);
            Swal.fire({
              title: "¡Error!",
              text: "No se pudo crear la sucursal.",
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          console.error("Error al crear sucursal:", error);
          setLoading(false);
          Swal.fire({
            title: "¡Error!",
            text: "Ocurrió un error al intentar crear la sucursal.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  const handleDeleteSucursal = (sucursalId) => {
    Swal.fire({
      title: "Eliminar sucursal",
      text: "¿Estás seguro de que deseas eliminar la sucursal?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`/sucursales/${sucursalId}`)
          .then((response) => {
            if (response.data.success) {
              // Si la solicitud fue exitosa, actualiza la lista de sucursales
              fetchSucursales();
              // Muestra un mensaje de éxito al sucursal
              Swal.fire({
                title: "¡Sucursal eliminada!",
                text: "La sucursal ha sido eliminado exitosamente.",
                icon: "success",
                confirmButtonText: "OK",
              });
            } else {
              // Si la solicitud no fue exitosa, muestra un mensaje de error al sucursal
              Swal.fire({
                title: "¡Error!",
                text: "No se pudo eliminar la sucursal.",
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            // Si hubo un error en la solicitud, muestra un mensaje de error al sucursal
            console.error("Error al eliminar la sucursal:", error);
            Swal.fire({
              title: "¡Error!",
              text: "Ocurrió un error al intentar eliminar la sucursal.",
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else {
        Swal.close();
      }
    });
  };

  const handleEditSucursal = async (sucursal) => {
    setEditMode(sucursal.id);
    setIdSucursalEdited(sucursal.id);
    setNumeroSucursal(sucursal.sucursalNumber || "");
    setDireccion(sucursal.direccion || "");
    setEncargado(sucursal.encargado || "");
    setCodigoPostal(sucursal.codigoPostal || "");
    setRazonSocial(sucursal.razonSocial || "");
    setCSD("");
    setPassCSD(sucursal.passCSD || "");
    setPrivateKey("");
    setRfc(sucursal.rfc || "");
  };

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      // Convierte el archivo a base64 cuando se carga
      reader.onloadend = () => {
        const base64String = reader.result;
        //Elimina el prefijo "data:application/x-x509-ca-cert;base64,"
        const cleanBase64 = base64String.split(",")[1];
        setFile(cleanBase64);
      };

      reader.readAsDataURL(file);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentSucursales(filterSucursales(page, searchTerm));
  };

  return (
    <div className="" style={{ position: "relative" }}>
      <Header></Header>
      <Loader isLoading={loading} />
      <div className="mt-5" style={{ width: "100%", margin: "0 auto" }}>
        <div className="" style={{marginTop: '0%'}}>
          {/* Columna 1 */}
          <div className="col-md-6" style={{  color: "black", margin: '0 auto' }}>
            <h2>
              {editMode !== null ? "Editar Sucursal" : "Crear Nueva Sucursal"}
            </h2>
            <form style={formStyles}>
              <div className="mb-3">
                <label htmlFor="numeroSucursal" className="form-label">
                  Folio de Sucursal
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="numeroSucursal"
                  value={numeroSucursal}
                  onChange={(e) => setNumeroSucursal(e.target.value)}
                  placeholder="Ingresa el folio de la sucursal"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  Dirección Fiscal
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  id="direccion"
                  value={direccion}
                  onChange={(e) => setDireccion(e.target.value)}
                  placeholder="Ingresa la dirección"
                  style={{borderColor: '#b9b7b7 !important'}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  Código Postal
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="direccion"
                  value={codigoPostal}
                  onChange={(e) => setCodigoPostal(e.target.value)}
                  placeholder="Ingresa el código postal"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  RFC
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="rfc"
                  value={rfc}
                  onChange={(e) => setRfc(e.target.value)}
                  placeholder="Ingresa RFC"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  Certificado fiscal (.cer)
                </label>
                <input
                  id="csd"
                  type="file"
                  ref={csdRef}
                  className="form-control"
                  onChange={(e) => handleFileChange(e, setCSD)}
                  placeholder="Carga el archivo .cer"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  Llave Privada (.key)
                </label>
                <input
                  id="llavePrivada"
                  ref={privateKeyRef}
                  type="file"
                  className="form-control"
                  onChange={(e) => handleFileChange(e, setPrivateKey)}
                  placeholder="Carga el archivo .key"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  Contraseña Certificado
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contraseñaCer"
                  value={passCSD}
                  onChange={(e) => setPassCSD(e.target.value)}
                  placeholder="Ingresa contraseña del certificado"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="direccion" className="form-label">
                  Razón Social
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="razonSocial"
                  value={razonSocial}
                  onChange={(e) => setRazonSocial(e.target.value)}
                  placeholder="Ingresa Razón Social"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="encargado" className="form-label">
                  Encargado
                </label>
                <select
                  className="form-select"
                  id="encargado"
                  value={idEncargadoFormulario || ""}
                  onChange={(e) => setIdEncargadoFormulario(e.target.value)}
                >
                  <option value="">Selecciona un encargado</option>
                  {encargados.map((enc) => (
                    <option key={enc.id} value={enc.id}>
                      {`${enc.name} ${enc.lastName} ${enc.secondLastName}`}
                    </option>
                  ))}
                </select>
              </div>
              {editMode !== null ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateOrUpdateBranch}
                >
                  Actualizar Sucursal
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateOrUpdateBranch}
                >
                  Crear Sucursal
                </button>
              )}
            </form>
          </div>

          {/* Columna 2 */}
          <div className="" style={{margin: '0 auto', padding: '0 30px' }}>
            <h2 style={{ color: "black" }}>Lista de sucursales</h2>
            <table
              className="table table-dark"
              style={{ borderRadius: "20px" }}
            >
              <thead>
                <tr>
                  <th style={{ borderTopLeftRadius: "20px" }}>
                    Folio sucursal
                  </th>
                  <th>Dirección Fiscal</th>
                  <th>Código Postal</th>
                  <th>RFC</th>
                  <th>Razón Social</th>
                  <th>Certificado</th>
                  <th>Llave Privada</th>
                  <th>Contraseña Certificado</th>
                  <th>Encargado</th>
                  <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {currentSucursales.map((item, index) => (
                  <tr key={index} style={{ verticalAlign: "middle" }}>
                    <td>{item.sucursalNumber}</td>
                    <td>{item.direccion}</td>
                    <td>{item.codigoPostal}</td>

                    <td>{item.rfc}</td>

                    <td>{item.razonSocial}</td>
                    <td> {item.CSD && item.CSD !== "" ? "Registrada" : "No registrada"}</td>
                    <td>{item.privateKey && item.privateKey !== "" ? "Registrada" : "No registrada"}</td>
                    <td>{item.passCSD && item.passCSD !== "" ? "Registrada" : "No registrada"}</td>
                    <td>
                      <span
                        className={
                          item.encargado === "Sin encargado"
                            ? "text-danger"
                            : ""
                        }
                      >
                        {item.encargado}
                      </span>
                    </td>
                    <td>
                      <div style={{ display: "grid" }}>
                        
                      {editMode === item.id ? (
                        <button
                        className="btn btn-primary"
                        onClick={() => handleCreateOrUpdateBranch(item)}
                        style={{ marginBottom: "5px" }}
                      >
                        Actualizar
                      </button>
                    ):(
                      <button
                        className="btn btn-primary"
                        onClick={() => handleEditSucursal(item)}
                        style={{ marginBottom: "5px" }}
                      >
                        Editar
                      </button>
                    )}

                      <button
                        className={`btn ${
                          item.active ? "btn-warning" : "btn-success"
                        }`}
                        onClick={() => {
                          if (item.active) {
                            handleToggleDeactive(item.id);
                          } else {
                            handleToggleActive(item.id);
                          }
                        }}
                        style={{ marginBottom: "5px" }}
                      >
                        {item.active ? "Desactivar" : "Activar"}
                      </button>

                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteSucursal(item.id)}
                      >
                        Eliminar
                      </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Paginación */}
            <Pagination
              totalPages={totalSucursales}
              itemsPerPage={10}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sucursales;
