import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import axios from "../../api/axiosConfig";
import Header from "../Header";
import Swal from "sweetalert2";
import QRCode from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Pagination from "./../Pagination";
import { io } from "socket.io-client";
import "../../index.css";

const TodosPedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PedidosPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [PedidosList, setPedidosList] = useState([]);

  const [currentPedidos, setCurrentPedidos] = useState([]);
  const [qrHash, setQrHash] = useState("");
  const userType = localStorage.getItem("userType");
  const navigate = useNavigate();
  const [totalPedidos, setTotalPedidos] = useState(0);


  useEffect(() => {
    if (userType === "0") {
      fetchPedidos();
    } else {
      fetchPedidosPorSuc();
    }
  }, [PedidosPerPage]);

  const fetchPedidos = async () => {
    try {
      const response = await axios.get("/pedidos");
      const fetchedPedidos = response.data;
      setPedidosList(fetchedPedidos);

      const totalPages = Math.ceil(fetchedPedidos.length / PedidosPerPage);
      setTotalPedidos(totalPages);

      setCurrentPedidos(fetchedPedidos.slice(0, PedidosPerPage));
    } catch (error) {
      console.error("Error al obtener pedidos:", error);
    }
  };

  const fetchPedidosPorSuc = async () => {
      try {
        const response = await axios.get(
          `/pedidosPorSuc/${localStorage.getItem(
            "branchId"
          )}`);
        const fetchedPedidos = response.data;
        setPedidosList(fetchedPedidos);
          
        const totalPages = Math.ceil(fetchedPedidos.length / PedidosPerPage);
        setTotalPedidos(totalPages);
  
        setCurrentPedidos(fetchedPedidos.slice(0, PedidosPerPage));
      } catch (error) {
        console.error("Error al obtener pedidos:", error);
      }
  };

      //Socket that detects new users created
      useEffect(() => {
        const socket = io(process.env.REACT_APP_API_BASE_URL,{
          extraHeaders: {
            'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
          },
          transports: ["websocket", "polling"]
        });
    
        // Escuchar eventos de nuevos clientes
        socket.on("NewPedidoAdded", () => {
          if (userType === "0") {
            fetchPedidos();
          } else {
            fetchPedidosPorSuc();
          }
        });
    
        return () => socket.disconnect(); // Limpiar al desmontar
      }, []);

      //Socket that detects pedidos updated
      useEffect(() => {
        const socket = io(process.env.REACT_APP_API_BASE_URL,{
          extraHeaders: {
            'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
          },
          transports: ["websocket", "polling"]
        });
    
        // Escuchar eventos de nuevos clientes
        socket.on("PedidoUpdated", () => {
          if (userType === "0") {
            fetchPedidos();
          } else {
            fetchPedidosPorSuc();
          }
        });
    
        return () => socket.disconnect(); // Limpiar al desmontar
      }, []);


      //Socket that detects pedidos deleted
      useEffect(() => {
        const socket = io(process.env.REACT_APP_API_BASE_URL,{
          extraHeaders: {
            'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
          },
          transports: ["websocket", "polling"]
        });
    
        // Escuchar eventos de nuevos clientes
        socket.on("PedidoDeleted", () => {
          if (userType === "0") {
            fetchPedidos();
          } else {
            fetchPedidosPorSuc();
          }
        });
    
        return () => socket.disconnect(); // Limpiar al desmontar
      }, []);

  const handleEditPedido = (pedidoId, nombreCliente, clienteId) => {
    navigate("/dashboard/editarPedido", { state: { pedidoId, nombreCliente, clienteId} });
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    setCurrentPedidos(filterPedidos(1, searchTerm));
  };

  const filterPedidos = (page, searchTermR) => {
    setSearchTerm(searchTermR);
    let registrosIni = (page - 1) * PedidosPerPage;
  
    let filtered = PedidosList.filter(pedido =>
      pedido.nombreCliente.toLowerCase().includes(searchTermR.toLowerCase())
    );
  
    const totalPages = Math.ceil(filtered.length / PedidosPerPage);
    setTotalPedidos(totalPages);
  
    return filtered.slice(registrosIni, registrosIni + PedidosPerPage);
  };

  const isDatePastOrToday = (fecha) => {
    const today = new Date();
    const dateToCheck = new Date(fecha);
    today.setHours(0, 0, 0, 0);
    dateToCheck.setHours(0, 0, 0, 0);
    return dateToCheck <= today;
  };

  const handleQr = (hash) => {
    const link = window.origin + "/=" + hash;
    setQrHash(link);
    Swal.fire({
      title: "Código QR",
      html: `<div id="qrCode" onclick="window.open('${link}', '_blank')" style="cursor:pointer;"></div>`,
      willOpen: () => {
        const qrCodeContainer = document.getElementById("qrCode");
        const root = createRoot(qrCodeContainer);
        root.render(<QRCode value={link} />);
      },
      confirmButtonText: "Cerrar",
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return date.toLocaleDateString("es-ES", options);
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleDetailsPedido = (pedidoId) => {
    navigate("/pedido/detalles", { state: { pedidoId } });
  };

  const handleDescriptionsPedido = (pedidoId) => {
    navigate("/pedido/descripcion", { state: { pedidoId } });
  };

  const handleReimprimirTickets = (idPedido) => {
    navigate("/pedido/tickets", { state: { idPedido } });
  };
  const handleEliminarPedido = (idPedido) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esta acción",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // Si el usuario confirma, hacemos la solicitud de eliminación
        axios
          .delete(`/pedido/eliminar/${idPedido}`)
          .then((response) => {
            // Verificamos si la respuesta fue exitosa
            if (response.data.success) {
              Swal.fire({
                title: '¡Eliminado!',
                text: 'El pedido ha sido eliminado correctamente.',
                icon: 'success',
              }).then(() => {
                // Después de que el usuario haga clic en "OK", recargamos la página
                window.location.reload();
              });
            }
          })
          .catch((error) => {
            console.error("Error al eliminar el pedido:", error);
            Swal.fire({
              title: 'Error',
              text: 'Ocurrió un error al eliminar el pedido.',
              icon: 'error',
            });
          });
      }
    });
  };

  const handleUltimaVisita= (idCliente, id) => {
    navigate("/visita/ultimaVisita", { state: { idCliente, id} });
  };

  const getFechaEntregaConEstilo = (pedido) => {
    const hoy = new Date();
  
    // Convertimos la fecha actual a YYYY-MM-DD en la zona horaria local
    const fechaHoyFormateada = `${hoy.getFullYear()}-${(hoy.getMonth() + 1).toString().padStart(2, '0')}-${hoy.getDate().toString().padStart(2, '0')}`;
  
    // Extraer solo la parte de la fecha de la cadena "YYYY-MM-DD"
    const fechaEntregaPrevista = pedido.fechaEntregaPrevista.split("T")[0];
  
    // Convertir la fecha a DD/MM/YYYY
    const [year, month, day] = fechaEntregaPrevista.split("-");
    const fechaEntregaFormateada = `${day}/${month}/${year}`;
  
    // Verificar si es el mismo día
    const esMismoDia = fechaHoyFormateada === fechaEntregaPrevista;
  
    // Mostrar en rojo solo si es el mismo día y el pedido no ha sido recibido o entregado
    const mostrarEnRojo =
      pedido.estatus !== "Recibido en sucursal" &&
      pedido.estatus !== "Entregado a cliente" &&
      esMismoDia;
  
    // Mostrar la fecha formateada
    return mostrarEnRojo ? (
      <span style={{ color: "red", fontWeight: '700', textDecoration: 'underline', cursor: 'pointer' }} title="La fecha de entrega prevista es hoy y todavía no se ha recibido en la sucursal.">
        {fechaEntregaFormateada}
      </span>
    ) : (
      fechaEntregaFormateada
    );
  };
  
  
  

const handlePageChange = (page) => {
  setCurrentPage(page);
  setCurrentPedidos(filterPedidos(page, searchTerm));
};

  return (
    <div>
      <Header />
      <div
        className="container contenedorTodosPedidos"
        style={{ maxWidth: "97%", marginTop: "50px" }}
      >
        <h2 className="my-4" style={{ color: "black" }}>
          Todos los Pedidos
        </h2>
        <div className="row mb-3">
          <div className="col-md-4">
            <span style={{ color: "black" }}>
              Buscar por Nombre del Cliente:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingresa el nombre"
              value={searchTerm}
              onChange={handleSearch}
            />
            {searchTerm !== "" ? (
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  setCurrentPedidos(pedidos);
                  setSearchTerm("");
                }}
                style={{ marginTop: "8px" }}
              >
                <i className="bi bi-x-lg">Borrar busqueda</i>
              </button>
            ) : null}
          </div>
        </div>
        {currentPedidos.length > 0 ? (
          <table className="table table-dark" style={{ borderRadius: "20px" }}>
            <thead>
              <tr>
                <th style={{ borderTopLeftRadius: "20px" }}>
                  Fecha de Creación
                </th>
                <th>Estatus</th>
                <th>Cliente</th>
                <th>Sucursal</th>
                <th>Último Modificador</th>
                <th>Última Modificación</th>
                <th>Entrega Prevista</th>
                <th>Saldo Pendiente</th>
                <th>Anticipo</th>
                <th>Total</th>
                <th>Tipo de Pago</th>
                <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currentPedidos.map((pedido) => (
                <tr key={pedido.id} className="tablaPedidos">
                  <td style={{ whiteSpace: "nowrap" }}>
                    {new Date(pedido.createDate).toLocaleDateString()}
                  </td>
                  <td>{pedido.estatus}</td>
                  <td>{pedido.nombreCliente}</td>
                  <td style={{ maxWidth: "350px" }}>
                    {pedido.direccionSucursal}
                  </td>
                  <td>{pedido.ultimoModificador}</td>
                  <td>{formatDate(pedido.ultimaModificacion)}</td>
                  <td>{getFechaEntregaConEstilo(pedido)}</td>
                  <td>${pedido.saldoPendiente.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td>${(pedido.anticipo ?? 0).toLocaleString('es-MX', { 
                    minimumFractionDigits: 2, 
                    maximumFractionDigits: 2 
                  })}</td>
                  <td>${pedido.total.toLocaleString('es-MX', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                  <td>{capitalizeFirstLetter(pedido.tipoPago)}</td>
                  <td style={{ display: "flex", flexDirection: "column" }}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleEditPedido(pedido.id, pedido.nombreCliente, pedido.idCliente)}
                    >
                      Editar
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => handleQr(pedido.HashId)}
                      style={{ marginTop: "5px" }}
                    >
                      Ver QR
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => handleUltimaVisita(pedido.id, pedido.id)}
                      style={{ marginTop: "5px" }}
                    >
                      Ver graduación
                    </button>

                    <button
                      className="btn btn-sm btn-success"
                      onClick={() => handleReimprimirTickets(pedido.id)}
                      style={{ marginTop: "5px", whiteSpace: 'nowrap' }}
                    >
                      Reimprimir Tickets
                    </button>

                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => handleDetailsPedido(pedido.id)}
                      style={{ marginTop: "5px", backgroundColor: "#5E5E5E" }}
                    >
                      Transacciones
                    </button>

                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => handleDescriptionsPedido(pedido.id)}
                      style={{ marginTop: "5px", backgroundColor: "#5E5E5E", whiteSpace: 'nowrap' }}
                    >
                      Detalles de Pedido
                    </button>
                    {
                      userType === "0" ? (
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => handleEliminarPedido(pedido.id)}
                          style={{ marginTop: "5px" }}
                        >
                          Eliminar
                        </button>
                      ) : null
                    }
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <>
            <h4 style={{ textAlign: "center", color: "lightgray" }}>
              No hay pedidos
            </h4>
          </>
        )}
        <Pagination
          totalPages={totalPedidos}
          itemsPerPage={10}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default TodosPedidos;
