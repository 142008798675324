import React, { useEffect, useState } from "react";
import axios from "../../api/axiosConfig";
import Header from "../Header";
import Swal from "sweetalert2";
import Pagination from "./../Pagination";
import { io } from "socket.io-client";
import "../../index.css";

const TodasCitas = () => {
  const [citas, setCitas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentCitas, setCurrentCitas] = useState([]);
  const [sucursalesList, setSucursalesList] = useState([]);
  const [selectedCitas, setSelectedCitas] = useState([]);
  const [citasList, setCitasList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const userType = localStorage.getItem("userType");
  const [CitasPerPage] = useState(10);
  const [totalCitas, setTotalCitas] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    fetchCitas();
    fetchSucursales();
  }, [CitasPerPage]);

  const fetchSucursales = () => {
    axios
      .get("/sucursales/active")
      .then((response) => {
        setSucursalesList(response.data.data);
      })
      .catch((error) => {
        console.error("Error al obtener sucursales:", error);
      });
  };

  const fetchCitas = async () => {
    if (userType === "0") {
      try {
        const response = await axios.get("/citas");
        const fetchedCitas = response.data;
        console.log(response.data);
        setCitasList(fetchedCitas);

        const totalPages = Math.ceil(fetchedCitas.length / CitasPerPage);
        setTotalCitas(totalPages);

        setCurrentCitas(fetchedCitas.slice(0, CitasPerPage));
      } catch (error) {
        console.error("Error al obtener las citas:", error);
      }
    } else {
      try {
        const response = await axios.get(
          `/citasPorSuc/${localStorage.getItem("branchId")}`
        );
        const fetchedCitas = response.data;
        setCitasList(fetchedCitas);

        const totalPages = Math.ceil(fetchedCitas.length / CitasPerPage);
        setTotalCitas(totalPages);

        setCurrentCitas(fetchedCitas.slice(0, CitasPerPage));
      } catch (error) {
        console.error("Error al obtener las citas:", error);
      }
    }
  };

   //Socket that detects new users created
      useEffect(() => {
        const socket = io(process.env.REACT_APP_API_BASE_URL,{
          extraHeaders: {
            'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
          },
          transports: ["websocket", "polling"]
        });
    
        // Escuchar eventos de nuevos clientes
        socket.on("NewCitaAdded", () => {
          fetchCitas();
        });
    
        return () => socket.disconnect(); // Limpiar al desmontar
      }, []);
    
     //Socket that detects users updated
      useEffect(() => {
        const socket = io(process.env.REACT_APP_API_BASE_URL,{
          extraHeaders: {
            'X-4B7F7C13A7D1': process.env.REACT_APP_API_TOKEN,
          },
          transports: ["websocket", "polling"]
        });
    
        // Escuchar eventos de nuevos clientes
        socket.on("CitaUpdated", () => {
          fetchCitas();
        });
    
        return () => socket.disconnect(); // Limpiar al desmontar
      }, []);
    

  const fetchCitasPorSuc = () => {
    axios
      .get(`/citasPorSuc/${localStorage.getItem("branchId")}`)
      .then((response) => {
        setCitas(response.data);
        setCurrentCitas(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error al obtener las citas:", error);
      });
  };

  const handleEditCita = (citaId, citaActual) => {
    const { fecha, hora, sucursalId, telefonoCliente } = citaActual;

    // Función para cargar las citas existentes del día seleccionado
    const cargarCitas = () => {
      const fechaCita = document.getElementById("fechaCita").value;
      const sucursalCita = document.getElementById("sucursalCita").value;

      if (!fechaCita || !sucursalCita) {
        return;
      }

      axios
        .get("/citas/disponibilidad", {
          params: { fecha: fechaCita, sucursalId: sucursalCita },
        })
        .then((response) => {
          const { results } = response.data;
          const tablaCitas = document.getElementById("tablaCitas");

          // Limpiar la tabla
          tablaCitas.innerHTML = `
            <thead>
              <tr>
                <th style="padding:5px">Hora</th>
                <th style="padding:5px">Cliente</th>
                <th style="padding:5px">Teléfono</th>
                <th style="padding:5px">Confirmada</th>
                <th style="padding:5px">Cancelada</th>
                <th style="padding:5px">Creador</th>
              </tr>
            </thead>
            <tbody id="bodyCitasT"></tbody>
          `;

          const tbody = tablaCitas.querySelector("tbody");

          if (results.length > 0) {
            results.forEach((cita) => {
              const tr = document.createElement("tr");

              tr.innerHTML = `
                <td>${cita.hora}</td>
                <td>${cita.clienteNombre}</td>
                <td>${cita.telefonoCliente}</td>
                <td>${cita.confirmada == "0" ? "No" : "Si"}</td>
                <td>${cita.cancelada == "0" ? "No" : "Si"}</td>
                <td>${cita.nombreCreador}</td>
              `;

              tbody.appendChild(tr);
            });
          } else {
            tbody.innerHTML =
              "<tr><td colspan='6' style='text-align:center;'>No hay citas para esta fecha.</td></tr>";
          }
        })
        .catch((error) => {
          console.error("Error al obtener las citas existentes:", error);
          Swal.fire("¡Error!", "Hubo un error al cargar las citas.", "error");
        });
    };

    Swal.fire({
      title: "Editar Cita",
      html: `
        <div class="ContenedorEditarCita">
          <div class="form-container" style="margin-bottom: 20px;">
            <label for="fechaCita" style="font-weight:700;">Fecha</label>
            <input type="date" id="fechaCita" class="swal2-input" value="${
              fecha.split("T")[0]
            }">
      
            <label for="sucursalCita" style="font-weight:700;">Sucursal</label>
            <select id="sucursalCita" class="swal2-input limited-width-select" style="margin-bottom:15px; margin-right:25px">
              <option value="">Selecciona una sucursal</option>
              ${sucursalesList
                .map(
                  (suc) =>
                    `<option value="${suc.id}" ${
                      suc.id === sucursalId ? "selected" : ""
                    }>${suc.sucursalNumber} - ${suc.direccion}</option>`
                )
                .join("")}
            </select>
            <label for="horaCita" style="font-weight:700;">Hora</label>
            <input type="time" id="horaCita" class="swal2-input" value="${hora}" style="margin-top:5px;margin-left:5px;"><br>
            <label for="telCliente" style="font-weight:700;">Teléfono Cliente</label>
            <input type="text" value="${telefonoCliente}" id="telCliente" class="swal2-input" maxlength="10" style="margin-top:5px;margin-left:5px;" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10);" placeholder="Teléfono (10 dígitos)">
          </div>
          <p style="font-weight:700; font-size: 22px;">Citas para el día seleccionado:</p>
          <div class="citas-container" style="max-height: 300px; overflow-y: auto;">
            <table id="tablaCitas" style="border-collapse: collapse; text-align: left;">
              <thead>
                <tr>
                  <th style="padding:5px">Hora</th>
                  <th style="padding:5px">Cliente</th>
                  <th style="padding:5px">Teléfono</th>
                  <th style="padding:5px">Confirmada</th>
                  <th style="padding:5px">Cancelada</th>
                  <th style="padding:5px">Creador</th>
                </tr>
              </thead>
              <tbody id="bodyCitasT"></tbody>
            </table>
          </div>
        </div>
      `,
      width: 1250,
      showCancelButton: true,
      confirmButtonText: "Guardar",
      cancelButtonText: "Cancelar",
      focusConfirm: false,
      didOpen: () => {
        const fechaInput = document.getElementById("fechaCita");
        const sucursalSelect = document.getElementById("sucursalCita");

        // Cargar citas cuando se abre el modal
        cargarCitas();

        // Evento para cargar citas cuando cambia la fecha o la sucursal
        fechaInput.addEventListener("change", cargarCitas);
        sucursalSelect.addEventListener("change", cargarCitas);
      },
      preConfirm: async () => {
        const fechaCita = document.getElementById("fechaCita").value;
        const horaCita = document.getElementById("horaCita").value;
        const sucursalCita = document.getElementById("sucursalCita").value;
        const telCliente = document.getElementById("telCliente").value;

        if (!fechaCita || !horaCita || !sucursalCita || !telCliente) {
          Swal.showValidationMessage("Por favor completa todos los campos");
          return false;
        }

        try {
          const response = await axios.get("/citas/disponibilidad", {
            params: { fecha: fechaCita, sucursalId: sucursalCita },
          });

          const { results } = response.data;

          // Verificar si la hora seleccionada ya está ocupada
          const horasOcupadas = results.map((cita) =>
            cita.hora.substring(0, 5)
          );

          if (horasOcupadas.includes(horaCita)) {
            Swal.showValidationMessage("Esta hora ya está ocupada.");
            return false; // Evitar que se cierre el modal si la hora está ocupada
          }

          // Si la hora no está ocupada, se continúa con la edición de la cita
          const sucursalDireccion = sucursalesList.find(
            (suc) => suc.id == sucursalCita
          );
          return {
            fechaCita,
            horaCita,
            sucursalCita,
            sucursalDireccion,
            telCliente,
          };
        } catch (error) {
          console.error("Error consultando disponibilidad:", error);
          Swal.showValidationMessage("Error al verificar disponibilidad.");
          return false;
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const {
          fechaCita,
          horaCita,
          sucursalCita,
          sucursalDireccion,
          telCliente,
        } = result.value;

        axios
          .put(
            `/citas/${citaId}`,
            {
              fecha: fechaCita,
              hora: horaCita,
              sucursalId: sucursalCita,
              sucursalDireccion: sucursalDireccion.direccion,
              telefonoCliente: telCliente,
            },
            {
              headers: {
                iduser: localStorage.getItem("idUser"),
                name: localStorage.getItem("name"),
                lastname: localStorage.getItem("lastName"),
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              if (userType === "0") {
                fetchCitas();
              } else {
                fetchCitasPorSuc();
              }

              Swal.fire(
                "¡Cita actualizada!",
                "Cita actualizada con éxito.",
                "success"
              );
            } else {
              Swal.fire(
                "Error",
                "Hubo un problema al actualizar la cita.",
                "error"
              );
            }
          })
          .catch((error) => {
            console.error("Error updating cita:", error);
            Swal.fire(
              "Error",
              "Hubo un problema al actualizar la cita.",
              "error"
            );
          });
      }
    });
  };

  const handleCancelarCita = (citaId, nombreCliente, cita) => {
    const Fecha = cita.fecha.split("T")[0];
    const fechaSecciones = Fecha.split("-");
    const anio = fechaSecciones[0];
    const mes = fechaSecciones[1];
    const dia = fechaSecciones[2];
    const fechaEntregaFormato = `${dia}/${mes}/${anio}`; 
    const Hora = cita.hora;
    const phoneNumber = "+52" + cita.telefonoCliente;
    const idSucursal = localStorage.getItem("branchId");
    axios.get("/sucursales").then((response)=>{
      const listaSucursales = response.data.data;
      const sucursalEncontrada = listaSucursales.find(
        sucursal => sucursal.id == idSucursal
       );
      const nombreOptica =  sucursalEncontrada
          ? sucursalEncontrada.direccion
          : null;

      Swal.fire({
        title: "Cancelación de cita",
        html: `¿Esta seguro que desea cancelar la cita de <b>${nombreCliente}</b> agendada el <b>${
          cita.fecha.split("T")[0]
        }</b> a las <b>${cita.hora}</b>?`,
        showCancelButton: true,
        confirmButtonText: "Confirmar cancelación",
        cancelButtonText: "Regresar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // Realizar la cancelacion de la cita en el backend
          if (phoneNumber != "+520000000000" && phoneNumber != "+52") {
            axios
            .post(`whatsapp/AvisoCancelacionCita`, {Fecha: fechaEntregaFormato, Hora, nombreOptica, phoneNumber})
            .then(() => {
              return axios.put(`/citas/cancelar/${citaId}`)
            })
            .then((response) => {
              if (response.status === 200) {
                // Cancelar la lista de citas en el estado
                if (userType === "0") {
                  fetchCitas();
                } else {
                  fetchCitasPorSuc();
                }
  
                Swal.fire(
                  "¡Cita cancelada!",
                  `La cita se ha cancelado con éxito.`,
                  "success"
                );
              } else {
                Swal.fire(
                  "Error",
                  "Hubo un problema al cancelar la cita.",
                  "error"
                );
              }
            })
            .catch((error) => {
              console.error("Error updating cita:", error);
              Swal.fire(
                "Error",
                "Hubo un problema al cancelar la cita.",
                "error"
              );
            });
          }else{
            axios
            .put(`/citas/cancelar/${citaId}`)
            .then((response) => {
              // Cancelar la lista de citas en el estado
              if (userType === "0") {
                fetchCitas();
              } else {
                fetchCitasPorSuc();
              }

              Swal.fire(
                "¡Cita cancelada!",
                `La cita se ha cancelado con éxito.`,
                "success"
              );
            }).catch((error) => {
              console.error("Error updating cita:", error);
              Swal.fire(
                "Error",
                "Hubo un problema al cancelar la cita.",
                "error"
              );
            });
          }
          
        }
      });
    })
  };

  const handleConfirmarCita = async (citaId, nombreCliente, cita) => {
    const Fecha = cita.fecha.split("T")[0];
    const fechaSecciones = Fecha.split("-");
    const anio = fechaSecciones[0];
    const mes = fechaSecciones[1];
    const dia = fechaSecciones[2];
    const fechaEntregaFormato = `${dia}/${mes}/${anio}`; 
    const Hora = cita.hora;
    const idSucursal = localStorage.getItem("branchId");
    const phoneNumber = "+52" + cita.telefonoCliente;
    
    axios.get("/sucursales").then((response)=>{
      const listaSucursales = response.data.data;
      const sucursalEncontrada = listaSucursales.find(
        sucursal => sucursal.id == idSucursal
       );
      const nombreOptica =  sucursalEncontrada
          ? sucursalEncontrada.direccion
          : null;
      
      Swal.fire({
        title: "Confirmación de cita",
        html: `¿Esta seguro que desea confirmar la cita de <b>${nombreCliente}</b> agendada el <b>${
          cita.fecha.split("T")[0]
        }</b> a las <b>${cita.hora}</b>?`,
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Regresar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // Realizar la cancelacion de la cita en el backend
          if (phoneNumber != "+520000000000" && phoneNumber != "+52") {
            axios
            .post(`/whatsapp/ConfirmacionCita`, { Fecha: fechaEntregaFormato, Hora, nombreOptica, nombreCliente, phoneNumber })  
            .then(() => {
              return axios.put(`/citas/confirmar/${citaId}`);
            })
            .then((response) => {
              if (response.status === 200) {
                // Confirmar la lista de citas en el estado
                if (userType === "0") {
                  fetchCitas();
                } else {
                  fetchCitasPorSuc();
                }
  
                Swal.fire(
                  "¡Cita confirmada!",
                  `La cita se ha confirmado con éxito.`,
                  "success"
                );
              } else {
                Swal.fire(
                  "Error",
                  "Hubo un problema al confirmar la cita.",
                  "error"
                );
              }
            })
            .catch((error) => {
              console.error("Error updating cita:", error);
              Swal.fire(
                "Error",
                "Hubo un problema al confirmar la cita.",
                "error"
              );
            });
          }else{
            axios
            .put(`/citas/confirmar/${citaId}`)
            .then(() => {
              // Confirmar la lista de citas en el estado
              if (userType === "0") {
                fetchCitas();
              } else {
                fetchCitasPorSuc();
              }

              Swal.fire(
                "¡Cita confirmada!",
                `La cita se ha confirmado con éxito.`,
                "success"
              );
            }).catch((error) => {
              console.error("Error updating cita:", error);
              Swal.fire(
                "Error",
                "Hubo un problema al confirmar la cita.",
                "error"
              );
            });;
          }
        }
      });
    });
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(1);
    setCurrentCitas(filterCitas(1, searchTerm));
    return;
  };

  //Funcion para filtrar usuarios por nombre de menera flexible
  const filterCitas = (page, searchTermR) => {
    setSearchTerm(searchTermR);
    let registrosIni = (page - 1) * CitasPerPage;

    let filtered = citasList.filter((cita) =>
      cita.clienteNombre.toLowerCase().includes(searchTermR.toLowerCase())
    );

    const totalPages = Math.ceil(filtered.length / CitasPerPage);
    setTotalCitas(totalPages);

    return filtered.slice(registrosIni, registrosIni + CitasPerPage);
  };

  const isDatePastOrToday = (fecha, cancelada) => {
    const today = new Date();
    const dateToCheck = new Date(fecha);

    if (cancelada === 1) {
      return "Cancelada";
    }

    // Reset hours to compare only dates
    today.setHours(0, 0, 0, 0);
    dateToCheck.setHours(0, 0, 0, 0);

    return dateToCheck >= today ? "Vigente" : "Vencida";
  };

  const handleSelectCita = (citaId) => {
    if (selectedCitas.includes(citaId)) {
      setSelectedCitas(selectedCitas.filter((id) => id !== citaId));
    } else {
      setSelectedCitas([...selectedCitas, citaId]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedCitas(currentCitas.map((cita) => cita.id));
    } else {
      setSelectedCitas([]);
    }
  };

  const handleCancelarMultiplesCitas = () => {
    selectedCitas.forEach(id => {
      console.log(id);
        axios.get(`/citaPorId/${id}`)
        .then(response => {
          const cita = response.data.data; // Asegúrate de acceder a los datos correctamente
          if (cita) {
            const Fecha = cita.fecha.split("T")[0]; 
            const Hora = cita.hora;
            const nombreOptica = "Opticos Pelayo";
            const phoneNumber = "+52" + cita.telefonoCliente;
            const nombreCliente = cita.clienteNombre; // Asegúrate de tener este dato también
  
            // Llamada al endpoint para cancelar la cita
            return phoneNumber != "+520000000000" && phoneNumber != "+52" ? axios.post("/whatsapp/AvisoCancelacionCita", { Fecha, Hora, nombreOptica, nombreCliente, phoneNumber }): true;
          } else {
            console.error("Cita no encontrada");
          }
        })
        .then(response => {
          console.log("Cancelación enviada:", response.data);
        })
        .catch(error => {
          console.error("Error al cancelar la cita:", error);
        });
    });
    if (selectedCitas.length === 0) {
      Swal.fire(
        "No hay citas seleccionadas",
        "Por favor, seleccione al menos una cita.",
        "info"
      );
      return;
    }
    Swal.fire({
      title: "Cancelación de múltiples citas",
      text: `¿Está seguro de que desea cancelar ${selectedCitas.length} citas?`,

      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/citas/cancelarMultiples", { ids: selectedCitas })
          .then((response) => {
            if (response.status === 200) {
              if (userType === "0") {
                fetchCitas();
              } else {
                fetchCitasPorSuc();
              }
              setSelectedCitas([]);
              setSelectAll(false);
              Swal.fire(
                "¡Citas canceladas!",
                "Las citas seleccionadas han sido canceladas con éxito.",
                "success"
              );
            } else {
              Swal.fire(
                "Error",
                "Hubo un problema al cancelar las citas.",
                "error"
              );
            }
          })
          .catch((error) => {
            console.error("Error al cancelar múltiples citas:", error);
            Swal.fire(
              "Error",
              "Hubo un problema al cancelar las citas.",
              "error"
            );
          });
      }
    });
  };

  // Filtra las citas por fecha
  const filterCitasByDate = (date) => {
    setSelectedDate(date);
    if (!date) {
      setCurrentCitas(
        citasList.slice(
          (currentPage - 1) * CitasPerPage,
          currentPage * CitasPerPage
        )
      );
      return;
    }

    const filteredCitas = citasList.filter(
      (cita) => new Date(cita.fecha).toISOString().split("T")[0] === date
    );

    setTotalCitas(Math.ceil(filteredCitas.length / CitasPerPage));
    setCurrentCitas(
      filteredCitas.slice(
        (currentPage - 1) * CitasPerPage,
        currentPage * CitasPerPage
      )
    );
  };

  // Maneja el cambio de la fecha en el input
  const handleDateChange = (e) => {
    filterCitasByDate(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setCurrentCitas(filterCitas(page, searchTerm));
  };

  return (
    <div>
      <Header />
      <div
        className=" contenedorTodasCitas"
        style={{ margin: '50px auto', padding: '0 10px' }}
      >
        <h2 className="my-4" style={{ color: "black" }}>
          Todas las Citas
        </h2>
        <div className="row mb-3">
          <div className="col-md-4">
            <span style={{ color: "black" }}>
              Buscar por Nombre del Cliente:
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="Ingresa el nombre"
              value={searchTerm}
              onChange={handleSearch}
              style={{marginBottom: '10px'}}
            />
            <span style={{ color: "black" }}>
              Filtro por fecha:
            </span>
            <input
              type="date"
              id="dateFilter"
              className="form-control"
              value={selectedDate}
              onChange={handleDateChange}
              style={{width: '150px'}}
            />

            {searchTerm !== "" ? (
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  setCurrentCitas(citas);
                  setSearchTerm("");
                }}
                style={{ marginTop: "8px" }}
              >
                <i className="bi bi-x-lg">Borrar búsqueda</i>
              </button>
            ) : null}
          </div>
        </div>
        <div className="mb-3">
          <button
            className="btn btn-danger"
            onClick={handleCancelarMultiplesCitas}
            disabled={selectedCitas.length === 0}
          >
            Cancelar Seleccionadas
          </button>
        </div>
        {currentCitas.length > 0 ? (
          <table className="table table-dark" style={{ borderRadius: "20px" }}>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th style={{ borderTopLeftRadius: "20px" }}>Fecha</th>
                <th>Hora</th>
                <th>Cliente</th>
                <th>Telefono</th>
                <th>Sucursal</th>
                <th>Ultima modificación</th>
                <th>Fecha de Creación</th>
                <th>Confirmada</th>
                <th>Cancelada</th>
                <th>Estado</th>
                <th style={{ borderTopRightRadius: "20px" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currentCitas.map((cita) => (
                <tr key={cita.id} className="citasTable">
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedCitas.includes(cita.id)}
                      onChange={() => handleSelectCita(cita.id)}
                    />
                  </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {new Date(cita.fecha).toLocaleDateString()}
                  </td>
                  <td>{cita.hora}</td>
                  <td>{cita.clienteNombre}</td>
                  <td>{cita.telefonoCliente}</td>
                  <td>{cita.sucursalDireccion}</td>
                  <td>{cita.nombreCreador}</td>
                  <td>{new Date(cita.createDate).toLocaleDateString()}</td>
                  <td>{cita.confirmada ? "Sí" : "No"}</td>
                  <td>{cita.cancelada ? "Sí" : "No"}</td>
                  <td>{isDatePastOrToday(cita.fecha, cita.cancelada)}</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() =>
                        handleEditCita(cita.id, cita, cita.clienteNombre)
                      }
                      style={{ margin: "0 5px 0 0" }}
                    >
                      Editar
                    </button>

                    {cita.cancelada === 0 && cita.confirmada === 0 ? (
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() =>
                          handleConfirmarCita(cita.id, cita.clienteNombre, cita)
                        }
                      >
                        Confirmar
                      </button>
                    ) : null}

                    {cita.cancelada === 0 ? (
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() =>
                          handleCancelarCita(cita.id, cita.clienteNombre, cita)
                        }
                        style={{ margin: "0 0px 0 5px" }}
                      >
                        Cancelar
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p style={{ color: "black" }}>No hay citas disponibles</p>
        )}
        <nav>
          <Pagination
            totalPages={totalCitas}
            itemsPerPage={10}
            onPageChange={handlePageChange}
          />
        </nav>
      </div>
    </div>
  );
};

export default TodasCitas;
